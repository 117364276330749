import Splide from "@splidejs/splide";
import {productClick, viewProducts} from "./algolia-insights";
import {clickEvent} from "./events";
import {document} from "../../../../../public/vendor/ssr-window";
import {ga4TrackSimpleEvent} from "./analytics";
import {isDesktop} from "./devices";

export function initProductPageRecommenders(productType)
{
    let recommenders = document.querySelectorAll('main.product .recommender');
    recommenders.forEach(function(recommender) {
        let numberOfSlides = recommender.querySelectorAll('.splide__slide').length;
        let splide = new Splide(recommender, {
            type: numberOfSlides > 7 ? 'loop' : 'slide',
            perPage: 7,
            perMove: 7,
            gap: "17px",
            pagination: false,
            rewind: true,
            breakpoints: {
                1450: {
                    type: numberOfSlides > 6 ? 'loop' : 'slide',
                    perPage: 6,
                    perMove: 6
                },
                1200: {
                    type: numberOfSlides > 4 ? 'loop' : 'slide',
                    perPage: 4,
                    perMove: 4
                },
                750: {
                    type: numberOfSlides > 2 ? 'loop' : 'slide',
                    perPage: 2,
                    perMove: 2,
                    arrows: false,
                    padding: { right: '23%'}
                }
            }
        }).mount();

        splide.on('mounted resize',function () {
            adjustArrows(recommender);
        });

        splide.on( 'moved', function () {
            if (isDesktop()) {
                ga4TrackSimpleEvent({
                    event: 'product_page',
                    method: 'recommender_scroll',
                    product_type: productType,
                    content_type: recommender.dataset.type || ''
                });
            }

            //DISABLE LAZY LOAD TO CLONED ITEMS
            let clonedItems = recommender.querySelectorAll('.splide__slide--clone.is-visible');
            clonedItems.forEach(function(clonedItem) {
                let img = clonedItem.querySelector('img');
                if (img.classList.contains('lazy')) img.src = img.dataset.src;
            });
        });
    });

    clickEvent('.recommender .recommendation', true, function (element, e) {
        productClick('Related', 'Click Product', element.dataset.id);
        let anchor = element.getElementsByTagName('a');
        ga4TrackSimpleEvent({
            event: 'product_page',
            method: 'recommender',
            content_type: anchor.length ? (anchor[0].dataset.type || '') : '',
            product_type: productType || '-',
            product_id: anchor.length ? (anchor[0].dataset.ecRef || '') : ''
        });
    });
}

export function initOldProductPageRecommenders()
{
    let recommenders = document.querySelectorAll('#product-page .recommender');
    recommenders.forEach(function(recommender) {
        new Splide(recommender, {
            type: 'loop',
            perPage: 7,
            perMove: 7,
            lazyLoad: 'nearby',
            pagination: false,
            breakpoints: {
                1450: {
                    perPage: 6,
                    perMove: 6
                },
                1200: {
                    perPage: 4,
                    perMove: 4
                },
                750: {
                    perPage: 3,
                    perMove: 3
                }
            }
        }).mount();
    });
    clickEvent('.recommender .recommendation', true, function (element, e) {
        productClick('Related', 'Click Product', element.dataset.id);
    });
}

export function initEmptyCartRecommender()
{
    return new Promise((resolve) => {
        let recommenders = document.querySelectorAll('#cart .recommender');
        recommenders.forEach(function (recommender) {
            let splide = new Splide(recommender, {
                type: 'loop',
                perPage: 2,
                perMove: 1,
                lazyLoad: 'nearby',
                gap: 8,
                pagination: false,
                rewind: true,
                arrows: false,
                padding: {right: '23%'}
            });

            splide.on('mounted resize', function () {
                let imageHeight = recommender.querySelector('img').offsetHeight;
                let arrowTop = imageHeight / 2;
                let arrows = recommender.querySelectorAll('.splide__arrow');
                arrows.forEach(function (arrow) {
                    arrow.style.top = arrowTop + "px";
                });
                recommender.querySelector('.splide__arrows').classList.remove('d-none');
                resolve();
            });

            splide.on('moved', function () {
                ga4TrackSimpleEvent({
                    event: 'product_page',
                    method: 'recommender_scroll',
                    content_type: recommender.dataset.type || ''
                });
            });

            splide.mount();
        });
    });
}

export function initCartRecommenders()
{
    let recommenders = document.querySelectorAll('#cart .recommender');
    recommenders.forEach(function(recommender) {
        new Splide(recommender, {
            type: 'loop',
            perPage: 5,
            perMove: 5,
            lazyLoad: 'nearby',
            pagination: false,
            breakpoints: {
                1200: {
                    perPage: 4,
                    perMove: 4
                },
                750: {
                    perPage: 3,
                    perMove: 3
                }
            }
        }).mount();
    });
}

export function initProductListRecommenders()
{
    let recommenders = document.querySelectorAll('#product-list .recommender');
    recommenders.forEach(function(recommender) {
        new Splide(recommender, {
            type: 'slide',
            perPage: 6,
            perMove: 6,
            lazyLoad: true,
            pagination: false,
            breakpoints: {
                1200: {
                    perPage: 5,
                    perMove: 5
                },
                750: {
                    perPage: 3,
                    perMove: 3
                }
            }
        }).mount();
    });
}

export function initNotFoundRecommenders(pagination = true)
{
    let recommenders = document.querySelectorAll('#not-found-recommenders .recommender');
    recommenders.forEach(function(recommender) {
        let numberOfSlides = recommender.querySelectorAll('.splide__slide').length;
        let splide = new Splide(recommender, {
            perPage: 7,
            perMove: 7,
            lazyLoad: 'nearby',
            pagination: false,
            gap: '20px',
            drag: false,
            type: numberOfSlides > 7 ? 'loop' : 'slide',
            arrows: numberOfSlides > 7,
            breakpoints: {
                1380: {
                    perPage: 5,
                    perMove: 5,
                    type: numberOfSlides > 5 ? 'loop' : 'slide',
                    arrows: numberOfSlides > 5
                },
                750: {
                    perPage: 2,
                    perMove: 2,
                    type: numberOfSlides > 2 ? 'loop' : 'slide',
                    padding: { right: '23%'},
                    arrows: false,
                    drag: numberOfSlides > 2,
                    pagination: pagination && numberOfSlides > 2,
                }
            }
        }).mount();
        adjustArrows(recommender);
        splide.on('mounted resize',function () {
            adjustArrows(recommender);
        });
    });
}

export function initNotResultsRecommenders()
{
    let recommenders = document.querySelectorAll('.no-results-recommender');
    recommenders.forEach(function(recommender) {
        let splide = new Splide(recommender, {
            perPage: 5,
            perMove: 5,
            pagination: false,
            rewind: true,
            drag: true,
            gap: '32px',
            breakpoints: {
                1200: {
                    perPage: 4,
                    perMove: 4
                },
                750: {
                    gap: 0,
                    perPage: 1,
                    perMove: 1,
                    focus: 0,
                    omitEnd: true,
                    autoWidth: true,
                }
            }
        }).mount();

        splide.on('mounted resize',function () {
            adjustArrows(recommender);
        });
    });
}

export function initLastViewedProductsRecommender(pagination = true)
{
    let recommenders = document.querySelectorAll('.recommender[data-type="last-viewed-products"]');
    recommenders.forEach(function(recommender) {
        let numberOfSlides = recommender.querySelectorAll('.splide__slide').length;
        let splide = new Splide(recommender, {
            perPage: 7,
            perMove: 7,
            lazyLoad: 'nearby',
            gap: "20px",
            pagination: false,
            drag: false,
            type: numberOfSlides > 7 ? 'loop' : 'slide',
            arrows: numberOfSlides > 7,
            breakpoints: {
                1380: {
                    perPage: 5,
                    perMove: 5,
                    type: numberOfSlides > 5 ? 'loop' : 'slide',
                    arrows: numberOfSlides > 5
                },
                750: {
                    perPage: 2,
                    perMove: 2,
                    type: numberOfSlides > 2 ? 'loop' : 'slide',
                    padding: { right: '23%'},
                    arrows: false,
                    drag: numberOfSlides > 2,
                    pagination: pagination && numberOfSlides > 2,
                }
            }
        }).mount();
        adjustArrows(recommender);
        splide.on('mounted resize',function () {
            adjustArrows(recommender);
        });
    });
}

function adjustArrows(recommender)
{
    let imageHeight = recommender.querySelector('img').offsetHeight;
    let arrowTop = imageHeight / 2;
    let arrows = recommender.querySelectorAll('.splide__arrow');
    arrows.forEach(function(arrow) {
        arrow.style.top = arrowTop + "px";
    });
    recommender.querySelector('.splide__arrows').classList.remove('d-none');
}

let algoliaProductsViewed = [];
export function sendAlgoliaRecommenderViews()
{
    let ids = [];
    document.querySelectorAll('.recommender .recommendation').forEach(function(element) {
        let id = element.dataset.id;
        if (!algoliaProductsViewed.includes(id)) {
            algoliaProductsViewed.push(id);
            ids.push(id);

            if (ids.length == 20) {
                viewProducts('Related', 'View Product', ids);
                ids = [];
            }
        }
    });

    if (ids.length) {
        viewProducts('Related', 'View Product', ids);
    }
}
export function renderAmazonPayButton(credentials)
{
    return new Promise(resolve => {
        waitApi(function () {
            let timeoutInitButton = setTimeout(function () {
                resolve();
            }, 30000);

            amazon.Pay.renderButton('#amazon-pay-button', {
                // set checkout environment
                merchantId: credentials.merchantId,
                ledgerCurrency: credentials.ledgerCurrency,
                sandbox: credentials.sandbox,
                checkoutLanguage: credentials.checkoutLanguage,
                productType: credentials.productType,
                placement: credentials.placement,
                buttonColor: credentials.buttonColor,
                // configure Create Checkout Session request
                createCheckoutSessionConfig: {
                    payloadJSON: JSON.parse(credentials.createCheckoutSessionConfig.payloadJSON),
                    signature: credentials.createCheckoutSessionConfig.signature,
                    publicKeyId: credentials.createCheckoutSessionConfig.publicKeyId
                }
            });

            verifyButtonIsInit(function() {
                clearTimeout(timeoutInitButton);
                resolve();
            });
        });
    });
}

function waitApi(callback)
{
    setTimeout(function() {
        if (typeof amazon !== 'undefined') {
            callback();
        } else {
            waitApi(callback);
        }
    }, 100);
}

function verifyButtonIsInit(callback)
{
    setTimeout(function() {
        let button = document.querySelector('#amazon-pay-button');
        if (button.shadowRoot) callback();
        else verifyButtonIsInit(callback);
    }, 10);
}




import {slideDown} from "./element-visibility";

export function renderPayPalButton(currency, total)
{
    return new Promise(resolve => {
        waitApi(function () {
            let timeoutInitButton = setTimeout(function () {
                resolve();
            }, 30000);

            var FUNDING_SOURCES = [
                paypal.FUNDING.PAYPAL,
                paypal.FUNDING.PAYLATER,
            ];
            FUNDING_SOURCES.forEach(function (fundingSource) {
                var button = paypal.Buttons({
                    fundingSource: fundingSource,
                    style: {
                        shape: 'rect',
                        color: (fundingSource == paypal.FUNDING.PAYPAL) ? 'gold' : (fundingSource == paypal.FUNDING.PAYLATER) ? 'gold' : 'black',
                        layout: 'horizontal',
                        size: 'responsive',
                        tagline: false
                    },
                    createOrder: function (data, actions) {
                        return actions.order.create({
                            purchase_units: [{
                                amount: {
                                    value: currency === 'JPY' ? Math.round(total) : total,
                                    currency: currency
                                },
                            }]
                        });
                    },
                    onApprove: function (data, actions) {
                        return actions.order.get().then(function (orderDetails) {
                            window.location.href = '/shopping/external-checkout?type=PaypalExpress&externalOrderReferenceId=' + orderDetails.id;
                        });
                    },
                    onInit() {
                        clearTimeout(timeoutInitButton);
                        resolve();
                    }
                });
                if (button.isEligible()) {
                    if (fundingSource == "paypal") button.render('#paypal-button');
                    if (fundingSource == "paylater") button.render('#paypal-button-paylater');
                }
            });
        });
    });
}

let dataToPay = '';

export function renderCheckoutPaypalButton(currency, total, address, terms, errorTerms, success)
{
    waitApi(function() {
        var FUNDING_SOURCES = [
            paypal.FUNDING.PAYPAL,
            paypal.FUNDING.PAYLATER,
        ];
        FUNDING_SOURCES.forEach(function (fundingSource) {
            let buttonColor = (fundingSource === paypal.FUNDING.PAYPAL) ? 'gold' : (fundingSource === paypal.FUNDING.PAYLATER) ? 'gold' : 'black';
            var button = paypal.Buttons({
                fundingSource: fundingSource,
                style: {
                    shape: 'rect',
                    color: buttonColor,
                    layout: 'horizontal',
                    size: 'responsive',
                    tagline: false
                },
                createOrder: function (data, actions) {
                    return actions.order.create({
                        purchase_units: [{
                            amount: {
                                value: currency === 'JPY' ? Math.round(total) : total,
                                currency: currency
                            },
                            shipping: {
                                name: {
                                    full_name: address.fullName,
                                },
                                address: {
                                    address_line_1: address.address,
                                    admin_area_2: address.city,
                                    admin_area_1: address.province,
                                    postal_code: address.postalCode,
                                    country_code: address.country
                                }
                            }
                        }],
                        application_context: {
                            shipping_preference: 'SET_PROVIDED_ADDRESS'
                        }
                    });
                },
                onApprove: function (data, actions) {
                    dataToPay = data;
                    success(data, actions);
                },
                onInit(data, actions)  {
                    actions.disable();
                    terms.addEventListener('change', function(event) {
                        if (terms.checked) actions.enable();
                        else actions.disable();
                    });
                },
                onClick()  {
                    if (!terms.checked) slideDown(errorTerms);
                }
            });
            if (button.isEligible()) {
                if (fundingSource === "paypal") button.render('#paypal-button');
                if (fundingSource === "paylater") button.render('#paypal-button-pay-later');
            }
        });
    });
}

export function getDataToPay()
{
    return dataToPay;
}

function waitApi(callback)
{
    setTimeout(function() {
        if (typeof paypal !== 'undefined') {
            callback();
        } else {
            waitApi(callback);
        }
    }, 100);
}